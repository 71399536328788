'use client';
import { useEffect } from 'react';
import { Button } from '@mui/material';
import Link from 'next/link';
import ProjectItem from '@/components/project/ProjectItem';
import ProjectItemSkeleton from '@/components/project/ProjectItemSkeleton';
import { useProjectList } from '@/hooks/useProjectList';
import { useTaskList } from '@/hooks/useTaskList';
import { useTenant } from '@/hooks/useTenant';
import styles from './page.module.scss';

export default function Home() {
  const { currentTenant } = useTenant();
  const { projects, error, isLoading } = useProjectList(currentTenant?.id);
  const { setTasks, setTaskParams } = useTaskList(true);

  useEffect(() => {
    setTasks([]);
    setTaskParams({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <main className={styles.main}>
      <div className="flex flex-col gap-3 rounded-lg px-2">
        {!isLoading && projects ? (
          projects.map((project, index) => <ProjectItem project={project} key={index} />)
        ) : (
          <>
            <ProjectItemSkeleton />
            <ProjectItemSkeleton />
            <ProjectItemSkeleton />
          </>
        )}
      </div>
      <Link href="/project/new">
        <Button className="m-10 p-2 bg-gray-300 rounded-md">新しいプロジェクトを作成する</Button>
      </Link>
    </main>
  );
}
